import React from 'react';
import Header from './components/Header'
import "./App.scss"
import {Footer} from './components/Footer'
import { routePath } from './common/routes';
import { Route, Routes } from 'react-router-dom';
import Faq from './components/Faq';
import { WebContextProvider } from './contextProvider/webContextProvider';

export const App =() => {
 
  return (
    <React.Fragment>
      {/* <Header /> */}
      <WebContextProvider>
      <Routes>
        {routePath.map((route) => {
          return <Route
            key={route.path}
            path={route.path}
            // exact={route.exact}
            element={<route.component />}
            // name={route.name}
          />
        })}
      </Routes>
      </WebContextProvider>
      {/* <Faq/>
      <Footer /> */}
    </React.Fragment>

  )
}
