export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any =
    [{ "text": "General Education", "value": "600" },
    { "text": "Adult Education & Learning", "value": "601" },
    { "text": "Early Childhood Development", "value": "602" },
    { "text": "Educational Administration", "value": "603" },
    { "text": "K-12 Education", "value": "604" },
    { "text": "Social Studies", "value": "605" },
    { "text": "Special Education", "value": "606" },
    { "text": "Teacher Liscensure", "value": "607" }
    ]

