import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { WebContext } from '../../contextProvider/webContextProvider';
import Loader from '../Loader';
import { leadFormUserInformationSchema } from '../../validation';
import { LeadFormUserInfoTitle } from '../../constants/leadForms';
import ReactInputMask from 'react-input-mask';
import CheckboxField from '../../helper/CheckboxField';
import { tikTokIdentify, tikTokTrack } from '../../Service/tikTok-analytics';
const time1 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/New_York',
  timeStyle: 'medium',
});
const time2 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/Chicago',
  timeStyle: 'medium',
});
const time3 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/Los_Angeles',
  timeStyle: 'short',
});
const time4 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/Anchorage',
  timeStyle: 'medium',
});
const time5 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/Denver',
  timeStyle: 'medium',
});
const time6 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/Phoenix',
  timeStyle: 'medium',
});
const time7 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/Halifax',
  timeStyle: 'medium',
});
const time8 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'Pacific/Honolulu',
  timeStyle: 'medium',
});
const time9 = new Date().toLocaleTimeString('en-GB', {
  timeZone: 'America/New_York',
  timeStyle: 'long',
});

const timeZones = [
  { label: 'Eastern Time (EST)', value: time1 },
  { label: 'Mountain Time (MST)', value: time5 },
  { label: 'Central Time (CST)', value: time2 },
  { label: 'Pacific Time (PST)', value: time3 },
  { label: 'Alaska Time (AKST)', value: time4 },
  { label: 'Arizona Time (MST)', value: time6 },
  { label: 'Atlantic Time (AST)', value: time7 },
  { label: 'Hawaii Time (AST)', value: time8 },
  { label: 'Indiana Time (EST)', value: time9 },
];

const leadOfferHandler = async (searchId: any) => {
  try {
    // let response;
    // do {
    //   response = await axios.get(
    //     'https://xz11y4jb21.execute-api.us-east-1.amazonaws.com/staging/v2/results',
    //     {
    //       params: {
    //         search_id: searchId,
    //         wait_time:10
    //       },
    //     }
    //   );

    //   if (!response?.data?.processing_done) {
    //     // Wait for a short duration before making the next request
    //     await new Promise(resolve => setTimeout(resolve, 100));
    //   }
    // } while (!response?.data?.processing_done);

    const response = await axios.get(
      'https://xz11y4jb21.execute-api.us-east-1.amazonaws.com/staging/v2/results',
      {
        params: {
          search_id: searchId,
          wait_time: 1,
        },
      }
    );
    return response;
  } catch (error: any) {
    console.warn('error', error);
    throw error; // rethrow the error so it can be caught in the calling function
  }
};

const LeadFormUserInformation = ({
  onNext,
  leadFormData,
  setLeadFormData,
  leadIdToken,
  trustedFormUrl,
  queryParams
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAvailableOffers,webContent   } = useContext(WebContext);
  const [localTcpa, setLocalTcpa] = useState({
    tcpaDisplay: '',
    tcpaCampaignID: '',
    mBrandID: '',
    mBrandName: '',
    tcpaBidPrice: '',
    smallLogo: '',
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      timeZone: '',
      // gender:'',
      check: false,
    },
    onSubmit: async values => {
      setIsLoading(true);
      setLeadFormData({
        ...leadFormData,
        userInformation: {
          userFirstName: values.firstName,
          userLastName: values.lastName,
          userEmail: values.email,
          userTimeZone: values.timeZone,
          userPhoneNumber: values.phoneNumber,
          userCheck: values.check,
          userGender: leadFormData.userInformation.userGender,
        },
      });

      
   tikTokIdentify({
    ...leadFormData,
    userInformation: {
      userFirstName: values.firstName,
      userLastName: values.lastName,
      userEmail: values.email,
      userTimeZone: values.timeZone,
      userPhoneNumber: values.phoneNumber,
      userCheck: values.check,
      userGender: leadFormData.userInformation.userGender,
    },
  });

  tikTokTrack({
    ...leadFormData,
    userInformation: {
      userFirstName: values.firstName,
      userLastName: values.lastName,
      userEmail: values.email,
      userTimeZone: values.timeZone,
      userPhoneNumber: values.phoneNumber,
      userCheck: values.check,
      userGender: leadFormData.userInformation.userGender,
    },
    ttclid:queryParams?.ttclid
  });

      try {
        // First API call
        await axios({
          method: 'POST',
          url: 'https://hooks.zapier.com/hooks/catch/6973915/3m2sphf/',
          params: {
            areaStudy: leadFormData?.areaStudy?.category_id,
            mProgram: leadFormData?.mProgram?.program_id,
            highestEducation: leadFormData?.highestEducationName.tcpaValue,
            gradYear: leadFormData.highestEducationYear.value,
            militaryAffiliation: leadFormData.militaryAffiliationName.tcpaValue
              ? leadFormData.militaryAffiliationName.tcpaValue
              : '1',
            startTimeFrame: leadFormData.duration.value,
            mPubID: webContent?.defaultData?.pubId,
            mPubCampaignID: webContent?.defaultData?.pubCampaignId,
            leadIdToken: leadIdToken,
            address: leadFormData.userLocation.userAddress,
            city: leadFormData.userLocation.userCity,
            zipCode: leadFormData.userLocation.userZipCode,
            state: leadFormData.userLocation.userState,
            campusType: leadFormData.userLocation.userCampusType.tcpaValue,
            trustedFormUrl: trustedFormUrl,
            tcpaDisplay: localTcpa.tcpaDisplay,
            tcpaCampaignID: localTcpa.tcpaCampaignID,
            mBrandID: localTcpa.mBrandID,
            mBrandName: localTcpa.mBrandName,
            tcpaBidPrice: localTcpa.tcpaBidPrice,
            smallLogo: localTcpa.smallLogo,
            hasRN: 'registered-nurse-no',
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        localStorage.removeItem('tcpaPingResponse');
        // Second API call
        const secondApiResponse: any = await axios({
          method: 'POST',
          url: 'https://xz11y4jb21.execute-api.us-east-1.amazonaws.com/staging/v2/searches',
          params: {
            partner_code: 'Jjo8vLs2A6IpMRU8KYrudw',
            test: 'true',
            lead_education: {
              education_level_id:
                leadFormData.highestEducationName.additionalValue,
              school_type:
                leadFormData.userLocation.userCampusType.additionalValue,
              grad_year: leadFormData.highestEducationYear.value,
              start_date: leadFormData.duration.additionalValue,
            },
            lead: {
              firstname: values.firstName,
              lastname: values.lastName,
              email: values.email,
              phone1: values.phoneNumber,
              service_leadid: leadIdToken,
              service_trusted_form: trustedFormUrl,
              gender: leadFormData.userInformation.userGender,
              signup_url: 'https://www.compareonlinedegrees.com/',
              besttime_contact: 'best-time-to-contact-anytime',
              // ip: '76.102.68.130',
            },
            lead_address: {
              zip: leadFormData.userLocation.userZipCode,
              city: leadFormData.userLocation.userCity,
              state: leadFormData.userLocation.userState,
              address: leadFormData.userLocation.userAddress,
            },
            lead_background: {
              military_type:
                leadFormData.militaryAffiliationName.additionalValue,
              teaching_license: 'teaching-license-no',
              rn_license: 'registered-nurse-no',
            },
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        const searchIdResponse = secondApiResponse.data;
        localStorage.setItem('search_id', searchIdResponse.search_id);
        const thirdApiResponse: any = await leadOfferHandler(
          searchIdResponse.search_id
        );
        // const thirdApiResponse: any = await axios({
        //   method: 'GET',
        //   url: 'https://xz11y4jb21.execute-api.us-east-1.amazonaws.com/staging/v2/results',
        //   params: {
        //     search_id: searchIdResponse.search_id,
        //   },
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //   },
        // });
        if (thirdApiResponse.data.offers.length > 0) {
          setAvailableOffers(thirdApiResponse.data.offers);
        } else {
          // setAvailableOffers(tempData);
        }
        setIsLoading(false);
        onNext();
      } catch (error) {
        setIsLoading(false);
        onNext();
        // Handle errors here
        console.error('Error:', error);
      }
    },
    validationSchema: leadFormUserInformationSchema,
  });

  useEffect(() => {
    const tcpaPingLocal = localStorage.getItem('tcpaPingResponse');
    setLocalTcpa(JSON.parse(tcpaPingLocal as any));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleKeyPress = (event: any) => {
    const charCode = event.key.charCodeAt(0);
    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      return;
    }
    event.preventDefault();
  };

  return isLoading ? (
    <Box textAlign="center">
      {' '}
      <Loader />
    </Box>
  ) : (
    <Box>
      <Box textAlign="center">
        <Typography
          component="h5"
          fontSize="30px"
          maxWidth="600px"
          lineHeight="1.3"
          mx="auto"
          fontWeight="795"
          mb={2}
        >
          {LeadFormUserInfoTitle}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={[2, 3]}>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              sx={{ background: 'white' }}
              label="First Name *"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              inputProps={{ onKeyPress: handleKeyPress }}
            />
            {errors.firstName && touched.firstName ? (
              <Box color="#db2424">{errors.firstName} </Box>
            ) : null}
          </Grid>

          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              sx={{ background: 'white' }}
              label="Last Name *"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              inputProps={{ onKeyPress: handleKeyPress }}
            />
            {errors.lastName && touched.lastName ? (
              <Box color="#db2424">{errors.lastName} </Box>
            ) : null}
          </Grid>

          {/* <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: '10px' }}>Gender *</label>
          <RadioGroup
            row
            aria-label="gender"
            name="gender"
            value={values.gender}
            onChange={handleChange}
          >
            <FormControlLabel value="M" control={<Radio />} label="Male" />
            <FormControlLabel value="F" control={<Radio />} label="Female" />
          </RadioGroup>
        </div>
    
        {errors.gender && touched.gender ? (
              <Box color="#db2424">{errors.gender} </Box>
            ) : null}

      </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{ background: 'white' }}
              label="Email *"
              name="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <Box color="#db2424">{errors.email} </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6}>
          <ReactInputMask
              mask="999-999-9999"
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {() => (
                <TextField
                  fullWidth
                  sx={{ background: 'white' }}
                  label="Phone Number *"
                  name="phoneNumber"
                  value={values.phoneNumber}
                />
              )}
            </ReactInputMask>
            {errors.phoneNumber && touched.phoneNumber ? (
              <Box color="#db2424">{errors.phoneNumber}</Box>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <FormControl sx={{ width: '100%', background: '#ffffff' }}>
              <InputLabel>Time Zone *</InputLabel>
              <Select
                label="Time Zone *"
                name="timeZone"
                onChange={handleChange}
                value={values.timeZone}
                onBlur={handleBlur}
              >
                {timeZones.map(timezone => (
                  <MenuItem value={timezone.value}>{timezone.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.timeZone && touched.timeZone ? (
              <Box color="#db2424">{errors.timeZone}</Box>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <CheckboxField
              label={localTcpa.tcpaDisplay}
              checked={values.check}
              onChange={handleChange}
              name="check"
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              // gap="16px"
              // mt={3}
            >
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: 'none',
                  fontSize: '18px',
                  borderRadius: '8px',
                  minWidth: '150px',
                  backgroundColor: webContent?.defaultData?.primaryColor,
                  '&:hover': {
                    backgroundColor: webContent?.defaultData?.primaryColor,
                },
                }}
                disabled={!(dirty && isValid)}
              >
                Request Info
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default LeadFormUserInformation;
