import FormIndex from "../components/LeadFormSteps/FormIndex";
import EducationTeachingListing from "../Container";

export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "General Education",
        path: "/education&teaching/generaleducation",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "Adult Education & Learning",
        path: "/education&teaching/adulteducation&learning",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "Early Childhood Development",
        path: "/education&teaching/earlychildhooddevelopment",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "Educational Administration",
        path: "/education&teaching/educationaladministration",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "K-12 Education",
        path: "/education&teaching/k-12education",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "Social Studies",
        path: "/education&teaching/socialstudies",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "Special Education",
        path: "/education&teaching/specialeducation",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "Teacher Liscensure",
        path: "/education&teaching/teacherliscensure",
        exact: true,
        component: EducationTeachingListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: EducationTeachingListing
    }
]
